import React, { useState }  from "react"
import Layout from "src/component/layout/layout"
import TabsCaseStudies from "src/component/common/tabs_casestudies"
import { Seo } from "src/component/common/seo"
import { getImage, withArtDirection } from "gatsby-plugin-image"
import { graphql } from "gatsby"

export default function Casestudies({ data }) {
    const [activeTab, setActiveTab] = useState("datastructuring");
    const [nextTab, setNextTab] = useState("datastructuring");
    const [fade, setFade] = useState(true);
    const mvImgPath = withArtDirection(getImage(data.mvImg.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.overviewSp.childImageSharp),
        },
    ])
    const mvImgPathSp = withArtDirection(getImage(data.mvImgSp.childImageSharp), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.mvImgSp.childImageSharp),
        },
    ])
        React.useEffect(() => {
        let js_scroll = document.getElementsByClassName('js-scroll');
        const scroll = () => {
            for (let i = 0; i < js_scroll.length; i++) {
                if ((window.pageYOffset + (window.innerHeight / 1.5)) > (window.pageYOffset + js_scroll[i].getBoundingClientRect().top)) {
                    js_scroll[i].classList.add('is-active');
                }
            }
        };
        scroll();
        window.addEventListener('scroll', () => {
            scroll();
        })
        }, [])
        
    
      const handleTabChange = (newTab) => {
       if (newTab === activeTab) return;
         setFade(false);
         setTimeout(() => {
         setActiveTab(newTab);
         setNextTab(newTab);
         setFade(true);
       }, 400);
      };


    
    return (
        <>
            <Layout>

                <div className="introduction">
                    <div className="introduction__inner c-inner-primary">
                        <h1 className="introduction__title title-bold">CASE STUDIES</h1>
                            <div className="introduction__item__catchcopy title-bold-catchcopy  c-pc js-scroll">
                                <div><div><span>ケーススタディ!</span></div></div>
                            </div>
                            <div className="introduction__item__catchcopy title-bold-catchcopy c-sp js-scroll">
                                <div><div><span>ケーススタディ!</span></div></div>
                        </div>
                    </div>
                </div>

                <TabsCaseStudies activeTab={activeTab} setActiveTab={handleTabChange} />

                <TabsCaseStudies activeTab={activeTab} setActiveTab={setActiveTab} />

            </Layout>
        </>
    )
}

export function Head() {
    return (
        <Seo
            title="CASE STUDIES"
            description=""
            pathname=""
        />
    )
}

export const query = graphql`
    query {
        allMicrocmsClient {
            edges {
                node {
                    id
                    logo {
                        url
                        width
                        height
                    }
                    name
                }
            }
        }
        allMicrocmsMember {
            edges {
                node {
                    name
                    id
                    memberId
                    content
                    photo {
                        width
                        url
                        height
                    }
                    position
                }
            }
        }
        mvImg: file(relativePath: {eq: "pages/industries/industries_food.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        mvImgSp: file(relativePath: {eq: "pages/industries/industries_food_sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
        overviewSp: file(relativePath: {eq: "pages/company/overview_img01-sp.png"}) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
            }
        }
    }
`