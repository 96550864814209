// import React from "react";
// import { Link } from "gatsby"

//   const tabs = [
//     { id: "data-structuring", label: "データ構造化" },
//     { id: "predictive-ai", label: "予測AI" },
//     { id: "analytical-ai", label: "分析AI" },
//     { id: "optimization-ai", label: "最適化AI" },
//     { id: "gx-solutions", label: "GXソリューション" },
//     { id: "geospatial-data", label: "地理空間データ" },
//   ];



// const TabsCaseStudies = ({ activeTab, setActiveTab }) => {
//    const handleTabClick = (tabId) => {
//     if (window.location.pathname === `/casestudies/${tabId}`) {
//       setActiveTab(tabId);
//     } else {
//       window.location.href = `/casestudies/${tabId}`;
//     }
//   };

//   return (
//     <section className="area-tabs">
//       <div className="area-tabs__inner">
//         {/* ページ遷移する */}
//         <ul className="area-tabs__wrap">
//           {tabs.map((tab) => {
//             return (
//               <li
//               key={tab.id}
//               className={`area-tabs__tab tab_button ${activeTab === tab.id ? "tab-active" : ""}`}
//             >
//               <Link 
//                 to={`/industries/${tab.id}`}
//                 onClick={() => handleTabClick(tab.id)}
//                 activeClassName="tab-active"
//                 className="tab-link"
//               >
//                 {tab.label}
//               </Link>
//             </li>
//             );
//           })}
//         </ul>
//       </div>
//     </section>
//   );
// };

// export default TabsCaseStudies;

import React from "react"
import { Link } from "gatsby"

const tabs = [
  { id: "data-structuring", label: "データ構造化" },
  { id: "predictive-ai", label: "予測AI" },
  { id: "analytical-ai", label: "分析AI" },
  { id: "optimization-ai", label: "最適化AI" },
  { id: "gx-solutions", label: "GXソリューション" },
  { id: "geospatial-data", label: "地理空間データ" },
]

const TabsCaseStudies = ({ activeTab }) => {
  return (
    <section className="area-tabs">
      <div className="area-tabs__inner">
        <ul className="area-tabs__wrap">
          {tabs.map((tab) => (
            <li
              key={tab.id}
              className={`area-tabs__tab tab_button ${activeTab === tab.id ? "tab-active" : ""}`}
            >
              <Link
                to={`/casestudies/${tab.id}`}
                className="tab-link"
              >
                {tab.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default TabsCaseStudies
